import axios from 'axios';
import React, { useState, useEffect } from 'react';

function Blocklist() {
  const [blockdata, setBlockdata] = useState([]);

  const fetchData = () => {
    let currentLogin = JSON.parse(window.localStorage.getItem("user"));
    axios.get('https://domaintobesocial.com/domaintobe/blockget', {
      params: {
        'userid': currentLogin.value
      }
    })
    .then(response => {
      if (response && response.data && response.data.message) {
        setBlockdata(response.data.message);
      } else {
        console.log('No data or unexpected data format in the response.');
      } 
    })
    .catch(err => {
      console.error('Error fetching data:', err);
      setBlockdata([]);
    });
  };

  const unblockUser = (userId) => {
    let currentLogin = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append('userid', currentLogin.value);
    formData.append('friendid', userId);

    axios.post('https://domaintobesocial.com/domaintobe/blockuser', formData)
    .then(response => {
      // Update the UI or refetch data after successful unblocking
      fetchData();
    })
    .catch(err => {
      console.error('Error unblocking user:', err);
      // Handle error, possibly show a message to the user
    });
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means it will only run once, similar to componentDidMount

  return (
    <div className="in_center in_center_discussion">
      {/* Your JSX code here */}
      <div className="my_followers">
        <div className="row">
          {blockdata.length > 0 ? blockdata.map(item => (
            <div className="col-lg-6 col-xl-4" key={item.id}>
              <div className="test">
                <span className="userimg"><img src={item.image} align="icon" alt={item.name} /></span>
                <h5>{item.name}</h5>
                <ul className="followmessage">
                  {/* Add Friend functionality */}
                  {/* <li><a href="#">Add Friend </a></li> */}
                  <li><a className="mg" onClick={() => unblockUser(item.id)}>UnBlock</a></li>
                </ul>
              </div>
            </div>
          )) : (
            <div className="norecord">
              <img src="/images/nodata.png" alt="No data" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blocklist;
