import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

function Help() {
    const [profession, setProfession] = useState([]);
    const [helpPosts, setHelpPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const[update,setUpdate]=useState(0)
    const [searchInput, setSearchInput] = useState('');
    const [searchedData, setSearchedData] = useState([]);
    const [input, setInput] = useState({
        profession: '',
        subprofession: '',
        description: '',
      });
      const [errors, setErrors] = useState({});
    const [popProfession, setPopProfession] = useState([]);
    const [subcategoryProfession, setSubcategoryProfession] = useState([]);
    const [helpimage, setHelpImage] = useState('');
    const [formfilled, setFormfilled] = useState('');
    const handleProfession = (event) => {
        setInput({
          ...input,
          [event.target.name]: event.target.value,
        });
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const formData = new FormData();
            formData.append('id',input.profession);
            const response = await axios.post(
              'https://domaintobesocial.com/domaintobe/getprofessionsubcategory',
              formData
            );
            setSubcategoryProfession(response.data.result);
          } catch (error) {
            console.log(error.message);
          }
        };
    
        if (input.profession) {
          fetchData();
        }
      }, [input.profession]);
    const validate = () => {
        let inputCopy = { ...input };
        let errorsCopy = {};
        let isValid = true;
      
        // Uncomment and update the validation logic as needed
        // if (!inputCopy["category"]) {
        //   isValid = false;
        //   errorsCopy["category"] = "Please select category.";
        // }
      
        if (!inputCopy["profession"]) {
          isValid = false;
          errorsCopy["profession"] = "Please select profession.";
        }
      
        if (!inputCopy["subprofession"]) {
          isValid = false;
          errorsCopy["subprofession"] = "Please select Subcategory profession.";
        }
      
        if (!inputCopy["description"]) {
          isValid = false;
          errorsCopy["description"] = "Please add description.";
        }
      
        // Assuming you have a hook or function named setErrors
        // You may need to update this line based on your component structure
        setErrors(errorsCopy);
      
        return isValid;
      };
      const imageChange = (event) => {
        const preview = document.querySelector('#myImg');
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.addEventListener("load", function () {
          // Convert image file to base64 string
          preview.src = reader.result;
        }, false);
    
        if (file) {
          reader.readAsDataURL(file);
          setHelpImage(file);
        }
      };
    const handleSubmit = async (event) => {
        event.preventDefault();
      
        if (formfilled === 'empty') {
          alert('Complete your personal details');
          window.location = "/userprofile";
          return false;
        } else {
          if (validate()) {
            document.getElementById('loadingicon').style.display = 'block';
            const obj = JSON.parse(window.localStorage.getItem("user"));
            const formData = new FormData();
            formData.append('profession', input.profession);
            formData.append('subprofession', input.subprofession);
            formData.append('description', input.description);
            formData.append('imgsrc', helpimage);
            formData.append('userid', obj.value);
      
            try {
              const res = await axios.post('https://domaintobesocial.com/domaintobe/helprequest', formData);
      
              document.getElementById('exampleModalHelp').style.display = 'none';
              const modalBackdrops = document.getElementsByClassName('modal-backdrop');
              document.body.removeChild(modalBackdrops[0]);
      
              if (res.data.message === 'success') {
                // Assuming you have a hook or function named componentDidMount
                // You may need to update this line based on your component structure
                // componentDidMount();
              } else {
                alert(res.data.message);
              }
              document.getElementById('loadingicon').style.display = 'none';
            } catch (error) {
              console.log(error.message);
            }
          }
        }
      };
      const handleChange = (event) => {
        setInput({
          ...input,
          [event.target.name]: event.target.value,
        });
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const professionResponse = await axios.get('https://domaintobesocial.com/domaintobe/getprofessions');
                setProfession(professionResponse.data.message);
                setPopProfession(professionResponse.data.message)
                const helpPostsResponse = await axios.get('https://domaintobesocial.com/domaintobe/gethelpposts');
                setHelpPosts(helpPostsResponse.data.message);

                setLoading(false);
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchData();
    }, []);
    const handleSearch = async (e) => {
        e.preventDefault();
    
        try {
          const formData = new FormData();
          formData.append('search', searchInput);
    
          const response = await axios.post('https://domaintobesocial.com/domaintobe/searchprofessions', formData);
          setSearchedData(response.data.message);
        } catch (error) {
          console.error(error.message);
        }
      };
  return (<>
    <div  className="in_center in_center_discussion help">
           <div className="head" >
                <form className="d-flex" onSubmit={handleSearch}>
                    <input className="form-control me-2" type="search" placeholder="Search" name="search" aria-label="Search" autoComplete="off" onChange={handleChange}
          value={searchInput}/>
                    <button className="btn" type="submit">
                    <img src="images/searchicon.png" alt="icon"/> </button>
                    <div className="setsearchdata">
                        <ul>
                            {searchedData.map((results) => {
                                return (
                                    <li><Link to={'professions/'+results.id}>{results.name}<i className="fas fa-arrow-right"></i></Link></li>
                                )
                            })}
                        </ul>
                    </div>
                </form>
                <a className="hpl" data-toggle="modal" data-target="#exampleModalHelp"><img src="images/iconS2.png" align="icon"/> <span>Help</span></a>
            </div>
          <div className="listusr discussion help">
            <div className="test">
                <div className="categoryhd">
                    <h3>Help Post</h3>
                </div>
                <div className="loadingicon" id="loadingicon" style={{ display: loading ? 'block' : 'none' }}>
                    <img src="/images/loading.gif" alt="Loading" />
                </div>
                <div className="allctg">
                    <div className="row">
                        {profession&&profession.map((results) => (
                            <div key={results.id} className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <Link to={`/professions/${results.id}`}>
                                    <div className="text">
                                        <img src="/images/iconS2.png" align="icon" alt="Icon" />
                                        <h3>{results.name}</h3>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                {helpPosts&&helpPosts.length > 0 ? (
                    <div className="row">
                        {helpPosts.map((result) => (
                            <div key={result.id} className="col-sm-6 col-lg-4 col-xl-3 mb-3">
                                <div className="singleposttest">
                                    <div className="asuser mb-0">
                                        <Link to={{ pathname: `/viewprofile/${result.name}` }}>
                                            <span className="userimg"><img src={result.userimage} align="icon" alt="User" /></span>
                                            <h5>{result.name}</h5>
                                        </Link>
                                        <p>{result.category}</p>
                                        <p>{result.created} Ago</p>
                                    </div>
                                    <div className="contants">
                                        <img className="w-100" src={result.image} alt="Content" />
                                        <p>{result.description}</p>
                                        <Link to={{ pathname: `/viewhelp/${result.id}` }}>View more <i className="fas fa-long-arrow-alt-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="norecord">
                        <img src="/images/nodata.png" alt="No Data" />
                    </div>
                )}
            </div>
        </div>
    </div>
    <div className="modal fade" id="exampleModalHelp"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content HelpForm">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle"> Medical / Legal...</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Professions</label>

                                    <select className="form-control" name="profession" value={input.profession} onChange={handleProfession}  id="profession">
                                        <option key="" value="">--Select Profession--</option>
                                        {popProfession.map((results) => {
                                            return (
                                                <option  value={results.id}>{results.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <div className="text-danger">{errors.profession}</div>
                                </div>
                            </div>
                            
                            <div className="col-sm-12">
                                <div className="form-group">
                                <label>Subcategory Profession</label>
                                    <select className="form-control"  value={input.subprofession} onChange={handleChange} name="subprofession" id="subprofession">
                                        <option key="" value="">--Select Subcategory--</option>
                                        {subcategoryProfession&&subcategoryProfession.map((results) => {
                                                return (
                                                    <option key={results.name} value={results.id} >{results.name}
                                                    </option>
                                                )
                                            })}
                                    </select>
                                    <div className="text-danger">{errors.subprofession}</div>
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea placeholder="Description" value={input.description} onChange={handleChange} id="description" name="description"></textarea>
                                    <div className="text-danger">{errors.description}</div>
                                </div>
                            </div>
                            <div className="col-sm-12 text-center">
                                <div className="form-group mb-0">
                                    <div className="userimgmain">
                                        <input type="file" onChange={imageChange} accept=".jpg,.jpeg,.png"/>
                                        <div className="userimg">
                                            <img id="myImg" className="h-100" src="images/usrrr.png" alt="your image"/>
                                        </div>
                                        <img className="camerai" src="images/camerai.png" alt="your image"/>
                                        <h6 className="mt-3">Upload Image</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit"  className="btn btn-primary submit">Submit</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}

export default Help
