import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import UpgreadePlan from "../UpgreadePlan/UpgreadePlan";

const Topbar = () => {

    const [showSubMenu, setShowSubMenu] = useState(false);
    const [categories, setCategories] = useState([]);
    const [plans, setPlans] = useState([]);
    const [professionsubcategory, setProfessionSubcategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [profession, setProfession] = useState([]);
    const [input, setInput] = useState({
        name: '',
        email: '',
        uid: '',
      });
      const [formfilled, setFormfilled] = useState('');
      const [isViprole, setIsViprole] = useState(false);
      const [verified , setverified ] = useState(false);
      const [userImage, setUserImage] = useState('/images/blank.png');
    let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    const handleSubMenuToggle = () => {
      setShowSubMenu(prevState => !prevState); // Toggle submenu visibility
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formData = new FormData();
                formData.append('id', curentlogin.value);
                formData.append('user', curentlogin.value);
        
                const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);
        
                setInput({
                  name: response.data.message.name,
                  email: response.data.message.email,
                  uid: response.data.message.id,
                });
                setverified(response.data.message.emailverified==='1'?true:false);
                setFormfilled(response.data.message.formfilled);
        
                if (response.data.message.roles === 'vip') {
                  setIsViprole(true);
                } else {
                  setIsViprole(false);
                }
        
                setUserImage(response.data.message.image || '/images/blank.png');
        
              } catch (error) {
                console.error('Error fetching data:', error.message);
              }
          try {
            const response = await axios.get('https://domaintobesocial.com/domaintobe/category');
            const sortedCategories = [...response.data.message];
    
            // Sort the categories array alphabetically based on the 'catname' property
            sortedCategories.sort((a, b) => {
              const nameA = a.catname.toUpperCase(); // Ignore case for comparison
              const nameB = b.catname.toUpperCase(); // Ignore case for comparison
    
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // Names are equal
            });
    
            setCategories(sortedCategories);
          } catch (error) {
            console.error('Error fetching data:', error);
          }

          try {
            const response = await axios.get('https://domaintobesocial.com/domaintobe/subcategory');
            setProfessionSubcategory(response.data.message2);
            setSubcategory(response.data.message);
          } catch (error) {
            console.error('Error fetching data:', error);
          }

          try {
            const formData = new FormData();
            formData.append('id', '16');
    
            const response = await axios.post('https://domaintobesocial.com/domaintobe/getprofessions', formData);
            setProfession(response.data.message);
          } catch (error) {
            console.error('Error fetching data:', error.message);
          }


          
        };
    
        fetchData();
      }, []);

     let handleChangeLogout=()=>
      {
        window.localStorage.clear();
        window.location.reload()
      }
      let vipimage;
      if(isViprole){
        vipimage = <img className="vip" src="/images/vip.png" align="icon"/>;
    }else{
        vipimage = '';
    }

    let stringValue = window.localStorage.getItem('user');
        if (stringValue !== null) {
            let value = JSON.parse(stringValue)
            let expirationDate = new Date(value.expirationDate)
            if (expirationDate > new Date()) {
                
            }else{
    
                window.localStorage.removeItem('user');
                window.location = "/";
            }
        }else{
            window.localStorage.removeItem('user');
            window.location = "/";
        }
  
        useEffect(() => {
          const fetchMembershipPlans = async () => {
            try {
              const response5 = await axios.post(
                "https://domaintobesocial.com/domaintobe/membershipplans"
              );
      
              if (response5 && response5.data && response5.data.result) {
                setPlans(response5.data.result);
              } else {
                console.log("No data or unexpected data format in the response.");
              }
            } catch (error) {
              console.log(error.message);
            }
          };
      
          fetchMembershipPlans();
        }, []);
  return (
    <div className="main_menu ">
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
    <div class="modal-content modal-Membership">
    <div class="custom-header text-center">
        <h5 class="modal-title" id="exampleModalCenterTitle">Membership</h5>
        <button type="button" class="close close-btn-rm" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex planpopup p-5">
      {plans&&plans?.map((plan, i) => (
        <div className="planbox  text-center" key={i}>
          <div className="testup">
            <div className="test">
              <div className="head_me">
                <h5>
                  {plan.duration} Plan
                  {input.plan === plan.id ? (
                    <span style={{ float: 'right', color: 'red' }}>
                      {input.planstatus}</span>
                  ) : (
                    <span></span>
                  )}
                </h5>
                <h4>${plan.price}</h4>
              </div>
              </div>
              {/* <h5>Features</h5>
              <ul>
              {plan.quickposting ? (
                                               <li>Quick Posting using feed along with features like attaching photo, video, tagging user and themselves too and using emojis</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.messageboard ? (
                                               <li>Posting on Message board to start a discussion</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.markingprofile ? (
                                               <li>Marking profile hidden while adding comments</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.customprofile ? (
                                               <li>Creating custom profile page with different colour theme, Banner, photos, video, bio, URL and Location</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.feed ? (
                                               <li>Posting feed or Discussion thread as private with custom duration and password access with share function</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.membershiprenewal  ? (
                                               <li>To get discounts on membership renewal by allowing ads on profile page</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.chatvideo  ? (
                                               <li>To receive requests for chat , video call and Help information</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.galleryfiles  ? (
                                               <li>Multiple delete of gallery files</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.posts  ? (
                                               <li>Search posts by date</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.livevideo  ? (
                                               <li>Live video streaming</li>
                                              ) : (
                                               <li></li>
                                            )}
              </ul> */}

              <UpgreadePlan price={plan.price} plan={plan.id} form={input.uid} />

              {input.plan === plan.id ? (
                <button style={{ float: 'right' }} className=" ">
                  Current Plan
                </button>
              ) : (
                <span></span>
              )}

              {input.plan === plan.id ? (
                <h5>
                  Renew on: <span> {input.expireddate}</span>
                </h5>
              ) : (
                <span></span>
              )}
            </div>
          </div>
      ))}
      </div>
     
    </div>
  </div>
</div>
      <ul className="Ticket-header">
        <li>
     
            {isViprole?<> <Link to="/userdashboard" className="active non-active"> <img className="vip-feed" src="/images/vip-feed.png" align="icon"/></Link></>:<> 
              
            <span
            className="dropdown-toggle active"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        
            onClick={handleSubMenuToggle}
          >
            News Feed
            
          </span>
          
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <ul>
              
              <li className="dropdown-item"  data-set="check"> <Link to="/userdashboard" className="active"> News Feed</Link></li>
              <li className="dropdown-item"  data-toggle="modal" data-target="#exampleModalCenter"> Vip Feed</li>
              {/* <li className="dropdown-item"  > <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button></li> */}

              </ul></div></>}    
       
        </li>
        <li className="dropdown">
          <span
            className="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={handleSubMenuToggle}
          >
            Discussion
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <ul>
              {categories&&categories.map((result) => (
                <li key={result.id} value={result.id} data-set="check">
                  <Link to={"/discussion?type=" + result.id} tabIndex="-1">
                    {result.catname}
                  </Link>
                  <ul className="testul">
                    {subcategory &&
                      subcategory.map((result1) =>
                        result1.catid === result.id ? (
                          <li
                            key={result1.id}
                            value={result1.id}
                            data-set="check"
                          >
                            <Link
                              to={
                                "/discussion?type=" +
                                result.id +
                                "&subtype=" +
                                result1.id
                              }
                            >
                              {result1.subcat}
                            </Link>
                          </li>
                        ) : null
                      )}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </li>

        <li className="dropdown">
          <span
            className="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Professional Help
          </span>
          <div className="dropdown-menu Prof" aria-labelledby="dropdownMenuButton">
            <ul>
              {profession&&profession.map((result) => {
                return (
                  <li data-set="check" key={result.id}>
                    <Link to={"/professions/" + result.id}>{result.name}</Link>
                    <ul className="testul">
                      {professionsubcategory &&
                        professionsubcategory.map((result1) =>
                          result1.pid === result.id ? (
                            <li
                              key={result1.id}
                              value={result1.id}
                              data-set="check"
                            >
                              <Link
                                to={
                                  "/professions/" +
                                  result.id +
                                  "&subtype=" +
                                  result1.id
                                }
                              >
                                {result1.name}
                              </Link>
                            </li>
                          ) : null
                        )}
                    </ul>
                  </li>
                );
              })}
              <li data-set="check">
                <Link to={"/help"}>All helps</Link>
              </li>
            </ul>
          </div>
        </li>
        {/* <li><Link to="/blog" >Blog</Link></li> */}
      </ul>
      <div className="side_right">
        <div className="asuser">
          <Link to="/userprofile">
            <span className="userimg">
              <img className="w-100" src={userImage} align="icon" />
            </span>
            {verified&&<img className="verfiy" src='../images/markver.png' align="icon" />}

            {vipimage}
            <h5>{input.name}</h5>
          </Link>
        </div>
        <span
          className="logout"
          onClick={handleChangeLogout}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default Topbar;
