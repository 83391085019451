import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useLocation } from 'react-router-dom'
import swal from 'sweetalert';


const Singlediscussion = () => {
    const [routeState, setRouteState] = useState(null);
    const [update, setupdate] = useState(0);
    const [showModal, setShowModal] = useState(true);
    const [data, setData] = useState([]);
    const [copied, setCopied] = useState(false);
    const [discussions, setDiscussions] = useState([]);
    const [input, setInput] = useState({ name: '' });
    const [userimage, setUserImage] = useState('/images/blank.png');
    const [formfilled, setFormFilled] = useState(''); // Assuming 'formfilled' is a string
    const [isViprole, setIsViprole] = useState(false);
    const [postlike, setPostLike] = useState(0);
    const [postcomments, setPostComments] = useState(0);
    const [postjoin, setPostJoin] = useState(0);
    const [postmemberjoins, setPostMemberJoins] = useState(0);
    const [discussionUser, setDiscussionUser] = useState("");
    const[values,setValues]=useState("");
    // const [did, setDid] = useState("");
    const [replyLikes, setReplyLikes] = useState(null);
    const [button, setButton] = useState("");
    const [joinButton, setJoinButton] = useState("");
    const [inputComment, setinputComment] = useState("");
    const [userId, setUserId] = useState("");
    const [hideComment, setHideComment] = useState('');
    const [errors, setErrors] = useState({});
    const [filePreview, setFilePreview] = useState('');

    const imageChange = (event) => {
      const preview = document.querySelector('#myImg');
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.addEventListener("load", function () {
        setFilePreview(reader.result);
      }, false);
  
      if (file) {
        reader.readAsDataURL(file);
      }
    };
    const handleHidecomment = (e) => {
      if (e.target.checked) {
        setHideComment(e.target.value);
      } else {
        setHideComment('');
      }
    }
    let location = useLocation();
    useEffect(() => {

        const checkLocalStorage = () => {
          const user = JSON.parse(window.localStorage.getItem("user"));
          if (user) {
            setUserId(user.value);
          }
        };
    
        checkLocalStorage();
      }, []);

      const validates = () => {
        let errors = {};
        let isValid = true;
    
        if (!input["category"]) {
          isValid = false;
          errors["category"] = "Category field is required.";
        }
    
        if (!input["description"]) {
          isValid = false;
          errors["description"] = "Description is required.";
        }
    
        setErrors(errors);
    
        return isValid;
      };
      const handleReportSubmit = (event) => {
        event.preventDefault();
    
        if (formfilled === 'empty') {
          swal("Oops", 'Complete your personal details', "error");
          window.location = "/userprofile";
          return false;
        } else {
          if (validates()) {
            var imgsrc = document.getElementById("myImg").src;
            var useimage = (imgsrc === 'https://localhost:3000/blank') ? '' : imgsrc;
    
            let userid = JSON.parse(window.localStorage.getItem("user"));
            let routeState = JSON.parse(window.localStorage.getItem("routeState"));
    
            const formData = new FormData();
            formData.append('did', routeState);
            formData.append('userid', userid.value);
            formData.append('category', input.category);
            formData.append('description', input.description);
            formData.append('image', useimage);
            
            axios.post('https://domaintobesocial.com/domaintobe/reportdiscussion', formData)
              .then((res) => {
                if (res.data.message === 'success') {
                  alert('Successfully submit');
                  window.location.reload();
                } else {
                  alert(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error.message);
              });
          }
        }
      };
    
      const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
      };
    useEffect(()=>{
        const fetchData = async () => {
        const queryParams = new URLSearchParams(location.search);
        const queries = Object.fromEntries(queryParams.entries());
        if (queries.id) {
            localStorage.setItem('routeState', JSON.stringify(queries.id));
            setRouteState(queries.id);
          } else {
            const storedRouteState = localStorage.getItem('routeState');
            if (storedRouteState) {
              setRouteState(JSON.parse(storedRouteState));
            }}
            let id ;
            if (routeState === null) {
                id = queries.id
                
                if (id ) {
                  setShowModal(false);
                  id = queries.id
 
                  const userid = JSON.parse(window.localStorage.getItem('user'));
      
                  // Fetch data based on the id and user id
                  const formData = new FormData();
                  formData.append('id', id);
                  formData.append('userid', userid.value);
      
                  const response = await axios.post('https://domaintobesocial.com/domaintobe/singlediscussion', formData);
      
                  if (response.data.status === 'data') {
                    const result = response.data.message; // Assuming there's only one result
                    console.log(result)
                    setData(result);
                    setPostLike(result[0].likes);
                    setPostComments(result[0].comments);
                    setPostJoin(result.joins);
                    setPostMemberJoins(result.members);
                  } else {
                    // Handle the case where the status is not 'data'
                    // For example, show an alert or reload the page
                  }
      
                  // Fetch discussions based on the id
                  const formData2 = new FormData();
                  formData2.append('id', id);
                  formData2.append('user', userid.value);
      
                  const response2 = await axios.post('https://domaintobesocial.com/domaintobe/getreplydiscussion', formData);
             
                  if (response2.data.status === 'data') {
                    setDiscussions(response2.data.message);
                  } else if (response2.data.status === 'no') {
                    setDiscussions([]);
                  } else {
                    alert(response2.data.message);
                    // window.location.reload();
                  }
      
                  // Fetch user profile data
                  const formData3 = new FormData();
                  formData3.append('id', id);
                  formData3.append('user', userid.value);
      
                  const response3 = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);
      
                  setInput((prevInput) => ({ ...prevInput, name: response3.data.message?.[0].name }));
                  setUserImage(response3.data.message?.[0].image || '/images/blank.png');
      
                  if (response3.data.message?.[0].roles === 'vip') {
                    setFormFilled(response3.data.message.formfilled);
                    setIsViprole(true);
                  } else {
                    setIsViprole(false);
                  }
                }
              } else {
                id = routeState;
              
                const userid = JSON.parse(window.localStorage.getItem('user'));
              
                // Fetch data based on the id and user id
                const fetchData = async () => {
                  try {
                    const formData = new FormData();
                    formData.append('id', id);
                    formData.append('userid', userid.value);
              
                    const res = await axios.post('https://domaintobesocial.com/domaintobe/singlediscussion', formData);
              
                    if (res.data.status === 'data') {
                      console.log(res.data)
                      setData(res.data.message);
                      res.data.message.forEach((result) => {
                        setPostLike(result.likes);
                        setPostComments(result.comments);
                        setPostJoin(result.joins);
                        setPostMemberJoins(result.members);
                      });
                    } else {
                      alert(res.data.message);
                      // window.location.reload();
                    }
                  } catch (error) {
                    console.log(error.message);
                  }
                };
              
                // Fetch discussions based on the id
                const fetchDiscussion = async () => {
                  try {
                    const formData = new FormData();
                    formData.append('id',id);
                    formData.append('userid', userid.value);
              
                    const response = await axios.post('https://domaintobesocial.com/domaintobe/getreplydiscussion', formData);
              
                    if (response.data.status === 'data') {
                      setDiscussions(response.data.message);
                    } else if (response.data.status === 'no') {
                      setDiscussions([]);
                    } else {
                      alert(response.data.message);
                      // window.location.reload();
                    }
                  } catch (error) {
                    console.log(error.message);
                  }
                };
                fetchDiscussion();
                // Fetch user profile data
                const fetchUserProfile = async () => {
                  try {
                    const formData = new FormData();
                    formData.append('id', userid.value);
                    formData.append('user', userid.value);
              
                    const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);
              
                    setInput((prevInput) => ({ ...prevInput, name: response.data.message.name }));
              
                    if (response.data.message.roles === 'vip') {
                      setFormFilled(response.data.message.formfilled);
                      setIsViprole(true);
                    } else {
                      setIsViprole(false);
                    }
              
                    setUserImage(response.data.message.image || '/images/blank.png');
                  } catch (error) {
                    console.log("error.message");
                  }
                };
              
                fetchData();
               
                // fetchUserProfile();
              }
              
          
          
        };

        fetchData();

          
    },[location.search,update,postjoin])

    const postJoin = (id, cat, description) => {
        if (formfilled === "empty") {
          swal("Oops", "Complete your personal details", "error");
          window.location = "/userprofile";
          return false;
        } else {
          const formData = new FormData();
          formData.append("did", routeState);
          formData.append("userid", userId);
          formData.append("postuser", id);
          formData.append("cat", cat);
          formData.append("description", description);
    
          axios.post("https://domaintobesocial.com/domaintobe/joindiscussion", formData)
            .then((res) => {
              if (res.data.message === "Joined") {
                setPostJoin(res.data.message);
                setPostMemberJoins(res.data.members);
              } else if (res.data.message === "Join") {
                setPostJoin(res.data.message);
                setPostMemberJoins(res.data.members);
              } else {
                alert(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      };
      const likePost = (id, cat, description) => {
        if (formfilled === "empty") {
          swal("Oops", "Complete your personal details", "error");
          window.location = "/userprofile";
          return false;
        } else {
          const formData = new FormData();
          formData.append("did", routeState);
          formData.append("userid", userId);
          formData.append("postuser", id);
          formData.append("cat", cat);
          formData.append("description", description);
    
          axios.post("https://domaintobesocial.com/domaintobe/discussionlike", formData)
            .then((res) => {
              console.log(res);
              if (res.data.message === "success") {
                setPostLike(res.data.likes);
              } else {
                alert(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      };

      const joinPost = (id, cat, description,did) => {
    
        if (formfilled === 'empty') {
          swal("Oops", 'Complete your personal details', "error");
          window.location = "/userprofile";
          return false;
        } else {
          let routeState = JSON.parse(window.localStorage.getItem("routeState"));
          const formData = new FormData();
          formData.append('did', did);
          formData.append('userid', userId);
          formData.append('postuser', id);
          formData.append('cat', cat);
          formData.append('description', description);
    
          axios.post('https://domaintobesocial.com/domaintobe/joindiscussion', formData)
            .then((res) => {
              if (res.data.message === 'Joined') {
                setPostJoin(res.data.message);
                setPostMemberJoins(res.data.members);
              } else if (res.data.message === 'Join') {
                setPostJoin(res.data.message);
                setPostMemberJoins(res.data.members);
              } else {
                alert(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      };
    
      const openModel = (event) => {
        let tag = event.currentTarget.dataset.tag;
      };
      const handleOpenLikereply = (i,id) => {
        if (formfilled === "empty") {
          swal("Oops", "Complete your personal details", "error");
          window.location = "/userprofile";
          return false;
        } else {
          let userid = JSON.parse(window.localStorage.getItem("user"));
          let routeState = JSON.parse(window.localStorage.getItem("routeState"));
          const formData = new FormData();
          formData.append("did", routeState);
          formData.append("userid", userid.value);
          formData.append("id", id);
    
          axios
            .post("https://domaintobesocial.com/domaintobe/likereplydiscussions", formData)
            .then((res) => {
              console.log(res);
              if (res.data.message === "Liked" || res.data.message === "Like") {
                setReplyLikes(res.data.message);
                setupdate(update+1)
              } else {
                alert(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      };
      
   let openReplycomment = (i,id)  => {
        document.getElementById(id).style.display = "block";
    }

    const handleReplySubmit = ( e,i) => {
      e.preventDefault();
  
      if (formfilled === 'empty') {
        swal("Oops", 'Complete your personal details', "error");
        window.location = "/userprofile";
        return false;
      } else {
        let userid = JSON.parse(window.localStorage.getItem("user"));
        let routeState = JSON.parse(window.localStorage.getItem("routeState"));
        const formData = new FormData();
        formData.append('did', routeState);
        formData.append('userid', userid.value);
        formData.append('comment', values[i]);
        formData.append('replyid', e.target.attributes['data-tag'].value);
  
        axios.post('https://domaintobesocial.com/domaintobe/discussionrepliescomments', formData)
          .then((res) => {
            console.log(res);
            if (res.data.message === 'success') {
              document.getElementById("reply" + e.target.attributes['data-tag'].value).value = '';
              document.getElementById(e.target.attributes['data-tag'].value).style.display = "none";
              alert('Successfully submit');
            } else {
              alert(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
 
      if (formfilled === 'empty') {
        swal("Oops", 'Complete your personal details', "error");
        window.location = "/userprofile";
        return false;
      } else {
        console.log("first")
    
       
          const formData = new FormData();
          formData.append('did', document.getElementById("did").value);
          formData.append('userid', document.getElementById("userid").value);
          formData.append('hidecomment', hideComment);
          formData.append('comment', inputComment);
          formData.append('discussionuser', document.getElementById("discussionuser").value);
          formData.append('postuser', data[0].userid);
          formData.append('cat', data[0].catname);
          formData.append('description', data[0].description);
  
          axios.post('https://domaintobesocial.com/domaintobe/replydiscussion', formData)
            .then((res) => {
              if (res.data.message === 'success') {
             setinputComment('');
             setupdate(update+1)
              } else {
                alert(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        
      }
    };
      const handleGetReply = ( e,i) => {
        // Ensure that 'e' is defined before accessing its properties
        if (e && e.target) {
          setValues({ ...values, [i]: e.target.value });
          console.log({ ...values, [i]: e.target.value })
        }
      };
      let discussionuser;
      let did ;
     
   
  return (
    <div className="in_center in_center_discussion">    <div className="head">
    <form className="d-flex">
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button className="btn" type="submit"><img src="/images/searchicon.png" alt="icon"/> </button>
    </form>
    <Link to="/createpost" className="hpl">
    <img src="/images/iconS2.png" align="icon"/> <span>Start Discussion</span></Link>
</div>

{data.length > 0  ? 
<div className="listusr discussion">
                        <div className="test ">
                            <img className="hearticon" src="/images/iconS8.png" align="icon"/>
                            <div className="asuser">
                           
                            <div>
      {data.map((result) => {
        discussionuser = result.userid;
         did = result.id;
        let button = "";
        let joinbutton = "";

        if (result.userid === userId) {
          button = "";
          joinbutton = "";
        } else {
          button = <a className="d_report" data-toggle="modal" data-target="#exampleModalHelp">Report</a>;
          joinbutton = (
            <div className="dropdown">
              <div className="join" onClick={()=>joinPost(result.userid, result.catname, result.description,did)}>
               Join <br /><img src="../images/tk.png" alt="icon" />
              </div>
            </div>
          );
        }
       

        return (
          <span key={result.id}>
            <Link to={"/viewprofile/" + result.name}>
              <span className="userimg">
                <img src={result.image} align="icon" />
              </span>
            </Link>
            <h5>
               <span>Posted By {result.name}</span>
              {button}
            </h5>
            <p>{result.created} Ago</p>
            {joinbutton}
            <p>{result.description}</p>
            <div className="row">
              <div className="col-sm-12">
                <div className="testin h-auto">
                  <img className="w-100" src={result.singleimage} alt="ion" />
                </div>
              </div>
            </div>
            <ul className="likecomment">
              <li onClick={() => likePost(result.userid, result.catname, result.description)}>
                <img src="/images/like.png" alt="ion" /> {postlike}
              </li>
              <li><img src="/images/comment.png" alt="ion" /> {postcomments}</li>
              <li><CopyToClipboard
                        text={`https://domaintobesocial.com/singlediscussion?id=${result.id}`}
                        onCopy={() => setCopied(true)}
                      ><img src="/images/shareI.png" alt="ion" /></CopyToClipboard>  {copied ? (
                        <span style={{ color: "red" }}>Copied.</span>
                      ) : null} {result.friendstags.length}</li>
              <li className="group_user">
                <img src="/images/group_user.png" alt="ion" /> {result.members} Members Joined
              </li>
            </ul>
          </span>
        );
      })}
    </div>
    <div className="allcomment singlediscussionsing">
      {discussions.length > 0 ? discussions.map((results, i) => (
        <>
          {results.hidecomment === "1" && isViprole !== false ?     
            <div className="commentin" key={results.id}>
              <span className="userimg"><img className="w-100" src={results.image} alt="icon" /></span>
              <h5>{results.name}<a className="reportbtn" data-tag={results.id} onClick={openModel}>Report {results.hidecomment}</a></h5>
              <p>{results.comment}( <span>{results.created}</span> )</p>
              <ul className="likecomment">
                <li data-tag={results.id} onClick={() => handleOpenLikereply( i,results.id)}>
                  <img src="/images/like1.png" alt="ion" />{results.likes}
                </li>
                <li onClick={() => openReplycomment(i, results.id)}>
                  <img src="/images/reply.png" alt="ion" /> Reply
                </li>
              </ul>
              <form className="replyid" data-tag={results.id} id={results.id} onSubmit={(e) => handleReplySubmit(e, i)}>
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Your Comment..."
                  aria-label="Search"
                  autoComplete="off"
                  id={'reply' + results.id}
                  value={values[i]}
                  name={values[i]}
                  onChange={(e) => handleGetReply(e, i)}
                />
                <button className="comment" type="submit">Comment</button>
              </form>
            </div>
          : <>
            {results.hidecomment === "1" && userId === results.discussionuser ? 
              <div className="commentin" key={results.id}>
                <span className="userimg"><img className="w-100" src={results.image} alt="icon" /></span>
                <h5>{results.name}<a className="reportbtn" data-tag={results.id} onClick={openModel}>Report {results.hidecomment}</a></h5>
                <p>{results.comment}( <span>{results.created}</span> )</p>
                <ul className="likecomment">
                  <li data-tag={results.id} onClick={ handleOpenLikereply(i, results.id)}>
                    <img src="/images/like1.png" alt="ion" />{results.likes}
                  </li>
                  <li onClick={() => openReplycomment(i, results.id)}>
                    <img src="/images/reply.png" alt="ion" /> Reply
                  </li>
                </ul>
                <form className="replyid" data-tag={results.id} id={results.id} onSubmit={(e) => handleReplySubmit(e, i)}>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Your Comment..."
                    aria-label="Search"
                    autoComplete="off"
                    id={'reply' + results.id}
                    value={values[i]}
                    name={values[i]}
                    onChange={(e) => handleGetReply(e, i)}
                  />
                  <button className="comment" type="submit">Comment</button>
                </form>
              </div>
            : <>
              {results.hidecomment === "0" ? 
                <div className="commentin" key={results.id}>
                  <span className="userimg"><img className="w-100" src={results.image} alt="icon" /></span>
                  <h5>{results.name}<a className="reportbtn" data-tag={results.id} onClick={openModel}>Report {results.hidecomment}</a></h5>
                  <p>{results.comment}( <span>{results.created}</span> )</p>
                  <ul className="likecomment">
                    <li data-tag={results.id} onClick={() => handleOpenLikereply(i, results.id)}>
                      <img src="/images/like1.png" alt="ion" />{results.likes}
                    </li>
                    <li onClick={() => openReplycomment(i, results.id)}>
                      <img src="/images/reply.png" alt="ion" /> Reply
                    </li>
                  </ul>
                  <form className="replyid" data-tag={results.id} id={results.id} onSubmit={(e) => handleReplySubmit(e, i)}>
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Your Comment..."
                      aria-label="Search"
                      autoComplete="off"
                      id={'reply' + results.id}
                      value={values[i]}
                      name={values[i]}
                      onChange={(e) => handleGetReply(e, i)}
                    />
                    <button className="comment" type="submit">Comment</button>
                  </form>
                </div>
              : ""}
            </>}
          </>}
        </>
      )) : ""}
    </div>
    <div className="likeshare">
      <form id="comment" onSubmit={handleSubmit}>
        {isViprole && (
          <div className="pcheck">
            <input type="checkbox" value="1" onChange={handleHidecomment} /> Hide comment
          </div>
        )}

        <input
          className="form-control me-2"
          type="search"
          placeholder="Your Comment..."
          aria-label="Search"
          name="comment"
          value={inputComment}
          onChange={(e)=>setinputComment(e.target.value)} 
          autoComplete="off"
          id="commentext"
        />
        <input type="hidden" value={did} id="did" />
        <input type="hidden" value={discussionuser} id="discussionuser" />
        <input type="hidden" value={userId} id="userid" />
      
        <button className="comment" type="submit">
          Comment
        </button>
      </form>
    </div>


                                </div></div></div>:""}

                                <div className="modal fade" id="exampleModalHelp"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content HelpForm">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Report</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleReportSubmit}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Categories</label>
                                        <select value={input.category} onChange={handleChange} name="category" id="category">
                                            <option key="" value="">--Select Category--</option>
                                            <option key="Abusive" value="Abusive">Abusive</option>
                                            <option key="Adult" value="Adult">Adult</option>
                                            <option key="Others" value="Others">Others</option>
                                        </select>
                                        <div className="text-danger">{errors.category}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea placeholder="Description" value={input.description} onChange={handleChange} id="description" name="description"></textarea>
                                        <div className="text-danger">{errors.description}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <div className="form-group mb-0">
                                        <div className="userimgmain">
                                            <input type="file" onChange={imageChange}/>
                                            <div className="userimg">
                                                <img id="myImg" className="h-100" src="blank" />
                                            </div>
                                            <img className="camerai" src="/images/camerai.png" alt="your image"/>
                                            <h6 className="mt-3">Upload Image</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit"  className="btn btn-primary submit">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
</div>
  )
}

export default Singlediscussion