import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import swal from 'sweetalert';

function Createpost() {
    const [input, setInput] = useState({});
    const [subcat, setSubcat] = useState([]);
    const [isViprole, setIsViprole] = useState(false);
    const [friends, setFriends] = useState([]);
    const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
    const [data, setData] = useState([]);
    const [files, setFiles] = useState([]);
    const [formfilled, setFormfilled] = useState("notempty");
    const [setEmail, setSetEmail] = useState('');
    const [inputValues, setInputValues] = useState('');
    const [inputEmail, setInputEmail] = useState([]);
    const [frientList, setFrientList] = useState([]);
    const [fruites, setFruites] = useState([]);
    const [addFriendTagVisible, setAddFriendTagVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [postId, setpostId] = useState(0);
    const [dataselectcat, setDataselectcat] = useState([]);
    const [addTagVisible, setAddTagVisible] = useState(false);
    const [checkvalue, setcheckvalue] = useState('');

    const [submition, setsubmition]=useState(false)
    const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserdata, setselectedUserdata] = useState(null);

  // Function to handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to perform user search
  const searchUsers = () => {
    let formData = new FormData();
    formData.append('name', searchQuery);
    axios.post('https://domaintobesocial.com/domaintobe/findUserByName', formData)
        .then((res) => {
            if (res.data.status === 'success') {
                setUsers(res.data.data);
            } else {
                setUsers([]);
            }
        })
        .catch((error) => {
            console.log(error.message);
        });
   
    // setUsers(filteredUsers);
  };

  // Function to handle user selection
  const handleUserSelect = (e) => {
    const userId = parseInt(e.target.value);

    const isChecked = e.target.checked;
    const updatedSelectedUserdata = Array.isArray(selectedUserdata) ? [...selectedUserdata] : [];

    if (isChecked) {
      setSelectedUser([...selectedUser, users.find(user => user.id == userId)]);
      updatedSelectedUserdata.push(userId);
      
    } else {
      setSelectedUser(selectedUser.filter(user => user.id != userId));
      const index = updatedSelectedUserdata.indexOf(userId);
    if (index !== -1) {
      updatedSelectedUserdata.splice(index, 1);
    }
    }
    setselectedUserdata(updatedSelectedUserdata);
  };
  
    function handleSubmit(event, state, setState, props) {
        event.preventDefault();

        if (!submition) {
            setsubmition(true);
            // const showAlert = () => {
            //     swal({
            //         title: 'Success!',
            //         text: 'Your post created successfully done',
            //         icon: 'success',
            //         confirmButtonText: 'Ok'
            //     });
            // };
            if (formfilled === 'empty') {
                alert('Complete your personal details');
                props.history.push('/userprofile');
                return false;
            } else {
                if (validate(input)) {
                    var obj = JSON.parse(window.localStorage.getItem("user"));
                    console.log(input)
                    const formData = new FormData();
                    formData.append('category', input.category);
                    formData.append('subcategory', input.subcategory);
                    formData.append('title', input.title);
                    formData.append('description', input.description);
                    formData.append('tags', fruites);
                    formData.append('tagfriends', inputEmail);
                    formData.append('url', input.url);
                    formData.append('vippost', input.vippost ? input.vippost : false);
                    formData.append('userid', obj.value);
                    formData.append('postId', postId);
                    formData.append('oldImage', input.oldImage);
                    formData.append('privatepost', input.private);
                    formData.append('viewpostaccess', checkvalue=="public"?0:checkvalue=="private"?1:checkvalue=="family"?2:'');
                    formData.append('viewpostaccessdata', selectedUserdata?selectedUserdata.join(","):"");
                    files.forEach((file) => formData.append('files[]', file));

                    axios.post('https://domaintobesocial.com/domaintobe/savediscussion', formData)
                        .then((res) => {
                            console.log('res', res);
                            if (res.data.message === 'success') {
                                swal({
                                    title: "Success!",
                                    text: "Your post created successfully done",
                                    icon: "success",
                                    confirmButtonText: "Ok"
                                })
                                 window.location.href ="/discussion"
                                
                            } else {
                                alert(res.data.message);
                                setState({ ...state, submition: true });
                            }
                        })
                        .catch((error) => {
                            setState({ ...state, submition: false });
                            alert(error.message);
                        });
                }
            }
        }
    }
    function validate(input) {
        let errors = {};
        let isValid = true;
        console.log(input.category)
        if (!input.category) {
            isValid = false;
            errors["category"] = "Please select category.";
        }

        // if (!input["subcategory"]) {
        //   isValid = false;
        //   errors["subcategory"] = "Please select subcategory.";
        // }

        if (!input.title) {
            isValid = false;
            errors["title"] = "Please add title.";
        }

        if (!input.description) {
            isValid = false;
            errors["description"] = "Please add description.";
        }

        return { isValid, errors };
    }
    // swal({
    //     title: 'Success!',
    //     text: 'Your post created successfully done',
    //     icon: 'success',
    //     confirmButtonText: 'Ok'
        
    // });
    const handleChange = (event) => {
      
        const { name, value } = event.target;
        
        setInput({ ...input, [name]: value });
       
        if (name === 'category') {
            console.log('under testing cat');
            axios.get('https://domaintobesocial.com/domaintobe/subcategory?catId=' + value)
                .then(response => {
                    setSubcat(response.data.message);
                })
                .catch(error => {
                    console.error('Error fetching subcategories:', error);
                });
        } else {
            console.log('under else part testing');
        }
    };
    const handleChangeaddtags = () => {
         document.getElementById('addtag').style.display = 'block';
    };
    const onClick = () => {
        if (inputValue) {
            const nextState = [...fruites, inputValue];
            setFruites(nextState);
            setInputValue('');
            document.getElementById('addtag').style.display = 'none';
        }
        console.log('fruites onClick', fruites);
    };
    const onChange = (e) => setInputValue(e.target.value);
    const handleChangeaddfriendtags = () => {
        setAddFriendTagVisible(true);
        document.getElementById('addfriendtag').style.display = 'block';
    };
    const onFriendClick = () => {
        if (inputValues) {
            const nextState = [...friends, inputValues];
            const nextStateDash = [...inputEmail, setEmail];
            setFriends(nextState);
            setInputValues('');
            setInputEmail(nextStateDash);
            document.getElementById('addfriendtag').style.display = 'none';
        }
    };
    const onFriendChange = (e) => {
        setInputValues(e.target.value);
        let formData = new FormData();
        formData.append('name', e.target.value);
        axios.post('https://domaintobesocial.com/domaintobe/findUserByName', formData)
            .then((res) => {
                if (res.data.status === 'success') {
                    setFrientList(res.data.data);
                } else {
                    setFrientList([]);
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    };
    function saveDraft(state) {
        var obj = JSON.parse(window.localStorage.getItem("user"));
        const formData = new FormData();
        formData.append('category', input.category);
        formData.append('subcategory', input.subcategory);
        formData.append('title', input.title);
        formData.append('description', input.description);
        formData.append('tags', fruites);
        formData.append('tagfriends', inputEmail);
        formData.append('url', input.url);
        formData.append('userid', obj.value);
        formData.append('oldImage', input.oldImage);
        formData.append('postID', postId);
        formData.append('privatepost', input.private);
        files.forEach((file) => formData.append('files[]', file));

        axios.post('https://domaintobesocial.com/domaintobe/savediscussionwithdraft', formData)
            .then((res) => {
                swal({
                    title: "Success!",
                    text: "save Draft created successfully done",
                    icon: "success",
                    confirmButtonText: "Ok"
                })
            })
            .catch((error) => {
                console.error('Error:', error);
                // Handle errors as needed
            });
    }
    function clearDraft(state) {
    if (postId !== 0) {
        var obj = JSON.parse(window.localStorage.getItem("user"));
        const formData = new FormData();
        
        formData.append('userId', obj.value);
        formData.append('postId', postId);
        axios.post('https://domaintobesocial.com/domaintobe/clearDraft', formData)
            .then((res) => {
                console.log('Draft cleared successfully');
                swal({
                    title: "Success!",
                    text: "Draft clear successfully done",
                    icon: "success",
                    confirmButtonText: "Ok"
                })
            })
            .catch((error) => {
                console.error('Error:', error);
                // Handle errors as needed
            });
    }
    
}

    const fetchData = async () => {
        try {
            const response = await axios.get('https://domaintobesocial.com/domaintobe/category');
            setDataselectcat(response.data.message);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }; 
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const curentlogin = JSON.parse(window.localStorage.getItem("user"));
                const formData2 = new FormData();
                formData2.append('id', curentlogin.value);
                const response = await axios.post('https://domaintobesocial.com/domaintobe/getDraftPost', formData2);

                let newInput = { ...input };
                newInput.description = response.data.message.description;
                newInput.category = response.data.message.catid;
                newInput.subcategory = response.data.message.subcatid;
                newInput.title = response.data.message.title !== undefined ? response.data.message.title : '';
                newInput.private = response.data.message.privatepost;
                newInput.oldImage = response.data.message.images;
                newInput.url = response.data.message.url && response.data.message !== undefined ? response.data.message : '';

                if (response.data.message.catid && response.data.message.catid !== 0 && response.data.message.catid !== undefined) {
                    const subcategoryResponse = await axios.get('https://domaintobesocial.com/domaintobe/subcategory?catId=' + response.data.message.catid);
                    setSubcat(subcategoryResponse.data.message);
                }

                setInput(newInput);
                setFruites(response.data.message.tags.split(','));
                setpostId(response.data.message.id);
                setFriends(response.data.message.tagfriends.split(','));
                setInputEmail(response.data.message.tagfriends.split(','));

                

                let img = response.data.message.images;
                if (img && img !== undefined && img !== '') {
                    let imgr = img.split(',');
                    let ice = [];
                    for (let index = 0; index < imgr.length; index++) {
                        ice.push('https://domaintobesocial.com/domaintobe/assets/allimages/' + imgr[index]);
                    }
                    // setImagesPreviewUrls(ice);
                }
            } catch (error) {
                console.log(error.message);
            }
            const curentlogin = JSON.parse(window.localStorage.getItem('user'));
        const formData = new FormData();
        formData.append('id', curentlogin.value);
        formData.append('user', curentlogin.value);

        const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);



        if (response.data.message.roles == 'vip') {
          setIsViprole(true);
        } else {
          setIsViprole(false);
        }

     
      };

        fetchData();
    }, []);
    useEffect(()=>{
        fetchData()
    },[])
    function sortDataByCatName(data) {
        return data.sort(function (a, b) {
            if (a.catname.toLowerCase() < b.catname.toLowerCase()) return -1;
            if (a.catname.toLowerCase() > b.catname.toLowerCase()) return 1;
            return 0;
        });
    }

    let dataselectcat1 = sortDataByCatName(dataselectcat);

    const _handleImageChange = (e) => {
        e.preventDefault();
        let filesArray = Array.from(e.target.files);

        filesArray.forEach((file) => {
            let reader = new FileReader();
            reader.onloadend = () => {
                setFiles(prevFiles => [...prevFiles, file]);
                setImagesPreviewUrls(prevUrls => [...prevUrls, reader.result]);
            };
            reader.readAsDataURL(file);
        });
    };
   
    const currentvalue = (name, dash) => {
        setInputValues(name);
        setSetEmail(dash);
        setFrientList([]);
    };
    let vipimage, privatepost;

    if (isViprole) {
        vipimage = <img className="vip" src="/images/vip.png" align="icon" />;
        privatepost = (
            <p className="vipp">
                <label className="checkcontainer">
                    <input type="checkbox" name="private" onChange={(e)=>checkboxChecked(e)} />
                    <span className="radiobtn"></span>
                </label>
                Make post private
            </p>
        );
    } else {
        vipimage = '';
        privatepost = '';
    }
      const checkboxChecked = (event) => {
   
    const { name, checked } = event.target;
    
    console.log(name);
    setInput(prevInput => ({
      ...prevInput,
      [name]: checked
    }));
  };
    const ListItem = ({ value, onClick }) => (
        <li onClick={onClick}>{value}</li>
    );

    // List component
    const List = ({ items, onItemClick }) => (
        <ul className="add_tag">
            {items.map((item, i) => (
                <ListItem key={i} value={item} onClick={onItemClick} />
            ))}
        </ul>
    );

    // Lists component (assuming it's intended to be similar to List)
    const Lists = ({ items, onItemClick }) => (
        <ul className="add_tag">
            {items.map((item, i) => (
                <ListItem key={i} value={item} onClick={onItemClick} />
            ))}
        </ul>
    );
    const handleItemClicks = function (e) {
        console.log(e.target.innerHTML);
    };

  return (
      <div className="in_center in_center_discussion pr-4">
          <div className="create_post">
              <h3>Create a Post</h3>
              <div className="create_in">
                  <form className="add_create" onSubmit={handleSubmit}>
                      <ul className="select_in">
                          <li>
                              <label>Select Category</label>
                              <select value={dataselectcat.id} onChange={handleChange} name="category" id="category">
                                  <option key="" value="">--Select Category--</option>
                                  {dataselectcat1 && dataselectcat1.map((result) => 
                                      <option key={result.id} value={result.id} data-set="check">{result.catname}</option>
                                  )}
                                  
                              </select>
                              <div className="text-danger">{data.errors}</div>
                          </li>
                          <li>
                              <label>Sub Category</label>
                              <select value={input.subcategory} onChange={handleChange} name="subcategory" id="subcategory">
                                  <option key="" value="">--Select Sub Category--</option>
                                  {subcat.map((result) => {
                                      return (
                                          <option key={result.id} value={result.id} data-catid={result.catid}>{result.subcat}</option>
                                      )
                                  })}
                              </select>
                              {/* <div className="text-danger">{this.errors.subcategory}</div> */}
                          </li>
                      </ul>
                      <input className="input" type="text" placeholder="Add Title..." name="title" onChange={handleChange} id="title" />
                      <div className="text-danger">{dataselectcat.errors}</div>
                      <h4>Description</h4>
                      <textarea placeholder="Write Here..." name="description" onChange={handleChange} id="description"></textarea>
                      <div className="text-danger">{dataselectcat.errors}</div>
                      <h4>Add Photos</h4>
                      <div>
                          <div className="row">
                              {imagesPreviewUrls.map((imagePreviewUrl) => (
                                  <div className="col-sm-3 col-lg-2 mb-4">
                                      <img className="upim w-100" key={imagePreviewUrl} alt='previewImg' src={imagePreviewUrl} />
                                  </div>
                              ))}
                              <div className="col-sm-3 col-lg-2 mb-3">
                                  <div className="addbtn">
                                      <input type="file" name="" onChange={_handleImageChange} multiple accept=".jpg,.jpeg,.png" />
                                      <i className="fas fa-plus"></i>
                                  </div>
                              </div>
                          </div>
                          <h4>Add Tags</h4>
                          <List items={fruites} onItemClick={handleItemClicks} />
                          <ul className="add_tag">
                              <span onClick={handleChangeaddtags} className="btn"><i className="fas fa-plus"></i></span>
                          </ul>
                          <div id="addtag">
                              <input type="text" placeholder="Tags" value={inputValue} onChange={onChange} id="inputtag" autoComplete="off" />
                              <button type="button" className="btn btn-sm btn-primary" onClick={onClick}>Submit</button>
                          </div>
                          <h4>
                              <Lists items={friends} onItemClick={handleItemClicks} />
                              <img src="images/dubleicon.png" alt="images" /> Tag Friends <span onClick={handleChangeaddfriendtags} className="btn"><i className="fas fa-plus"></i></span>
                          </h4>
                          <div id="addfriendtag">
                              <input type="text" placeholder="Friend Tags" autoComplete="off" value={inputValues} onChange={onFriendChange} />
                              <button type="button" className="btn btn-sm btn-primary" onClick={onFriendClick}>Submit</button>
                          </div>
                          <ul>
                              {frientList.map((result, i) => (
                                  <li key={i} value={result.email} onClick={(e) => currentvalue(result.name, result.email)}>{result.name}</li>
                              ))}
                          </ul>
                          <div className="url">
                        
                              <img src="images/addicon4.png" alt="images" />
                              <input className="input" type="text" placeholder="Add url" name="url" value={input.url} onChange={handleChange} />
                          </div>
                          {privatepost}
                          {isViprole && (
        <p className="vipp">
          <label className="checkcontainer">
            <input type="checkbox" name="vippost" onChange={checkboxChecked} />
            <span className="radiobtn"></span>
          </label>
          Select VIP Post
        </p>
      )}    
 <div className="form-check mt-4">
    <input
      className="form-check-input"
      type="radio"
      name="flexRadioDefault"
      id="flexRadioDefault1"
      value={"public"}
      onChange={(e)=>setcheckvalue(e.target.value)}
    />
    <label className="form-check-label" htmlFor="flexRadioDefault1">
     Public
    </label>

  </div>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name="flexRadioDefault"
      id="flexRadioDefault2"
      defaultChecked=""
      value={"private"}
      onChange={(e)=>setcheckvalue(e.target.value)}
    />
    <label className="form-check-label" htmlFor="flexRadioDefault2">
      Private (Private posts will be visible only to friends and you.)
    </label>
{ checkvalue=="private"?<><div>
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search Friends Members..."
      />
      <button type='button' onClick={searchUsers}>Search</button>
      
      {users.length > 0 && (
        <div>
          <h2>Select Users</h2>
          {users.map(user => (
            <div key={user.id}>
              <input
                type="checkbox"
                id={user.id}
                value={user.id}
                onChange={handleUserSelect}
              />
              <label htmlFor={user.id}>{user.name}</label>
            </div>
          ))}
        </div>
      )}

      {selectedUser && selectedUser.length > 0 && (
        <div>
          <h4>Selected Users</h4>
          <ul>
            {selectedUser.map(user => (
              <li key={user.id}>
                {user.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
</>:""}
  </div>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name="flexRadioDefault"
      id="flexRadioDefault2"
      defaultChecked=""
      value={"family"}
      onChange={(e)=>setcheckvalue(e.target.value)}
    />
    <label className="form-check-label" htmlFor="flexRadioDefault2">
    Family  Only (Private posts will be visible only to Family and you.)
    </label>
    { checkvalue=="family"?<> <div>
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search Family Members..."
      />
      <button type='button' onClick={searchUsers}>Search</button>
      
      {users.length > 0 && (
        <div>
          <h2>Select Users</h2>
          {users.map(user => (
            <div key={user.id}>
              <input
                type="checkbox"
                id={user.id}
                value={user.id}
                onChange={handleUserSelect}
              />
              <label htmlFor={user.id}>{user.name}</label>
            </div>
          ))}
        </div>
      )}

      {selectedUser && selectedUser.length > 0 && (
        <div>
          <h4>Selected Users</h4>
          <ul>
            {selectedUser.map(user => (
              <li key={user.id}>
                {user.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
</>:""}
  </div>
                          <ul className="save_draft">
                              <li><a onClick={saveDraft} className="btn">Save as Draft</a></li>
                              <li><a onClick={clearDraft} className="btn">Discard</a></li>
                              <li><button type="submit" className="postbtn btn">Post</button></li>
                          </ul>
                          
                      </div>
                  </form>
              </div>
          </div>
          </div>

  )

     }
                
export default Createpost