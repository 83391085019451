import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
function Normalsignup() {
    
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState({firstname:'',lastname:'',username:'',password:'',email:'',mobile:'',age:'',referral:''});
    const [input, setInput] = useState({firstname:'',lastname:'',username:'',password:'',email:'',mobile:'',age:'',referral:''});
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 30)));
    const[termcondition,settermcondition]=useState('')
    const[errorMessageapi,seterrorMessageapi]=useState('')
    const location = useLocation();

    useEffect(() => {
        const queries = {};
        const queryParams = new URLSearchParams(location.search);
        for (const [key, value] of queryParams.entries()) {
          // Use the key as the value if the original value is an empty string
          queries["data"] = value !== '' ? value : key;
        }
        
        // Check if the referral value has changed before updating state
        if (input.referral !== queries.data) {
          setInput(prevState => ({
            ...prevState,
            referral: queries.data ? queries.data : ""
          }));
        }
    }, [input.referral]);
    
      
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput({ ...input, [name]: value });
    };

    const validate = () => {
        let errors = {};
        let isValid = true;
    
        if (!input["username"]) {
          isValid = false;
          errors["username"] = "Please enter your username.";
        }
    
        if (typeof input["username"] !== "undefined") {
          const re = /^\S*$/;
          if(input["username"].length < 6 ){
            isValid = false;
            errors["username"] = "Please enter valid username.";
          }
        }
    
        if (typeof input["mobile"] !== "undefined") {
          const rep = /^\d*$/;
          if(input["mobile"].length < 10 || !rep.test(input["mobile"])){
            isValid = false;
            errors["mobile"] = "Please enter your valid 10 digit mobile.";
          }
        }
    
        if (!input["age"]) {
          isValid = false;
          errors["age"] = "Please enter your age.";
        }
    
        if (!input["firstname"]) {
          isValid = false;
          errors["firstname"] = "Please enter your firstname.";
        }
    
        if (!input["lastname"]) {
          isValid = false;
          errors["lastname"] = "Please enter your lastname.";
        }
    
        if(!input['age']) {
          isValid = false;
          errors['age'] = "Please fill the input"
        } else {
          var parts = input["age"].split("-"); 
          var dtDOB = new Date(parts[2] + "/" + parts[1] + "/" + parts[0]);
          var dtCurrent = new Date();
          if(dtCurrent.getFullYear() - parts[0] < 16 ) {
            isValid = false;
            errors["age"] = "Eligibility minimum 16 years.";
          }
    
          if (dtCurrent.getFullYear() - dtDOB.getFullYear() == 16) {
            if (dtCurrent.getMonth() < parts[1]) {
              isValid = false;
              errors["age"] = "Eligibility minimum 16 years.";
            }
            if (dtCurrent.getMonth() == parts[1]) {
              if (dtCurrent.getDate() < parts[0]) {
                isValid = false;
                errors["age"] = "Eligibility minimum 16 years.";
              }
            }
          }
        }
    
        if (!input["email"]) {
          isValid = false;
          errors["email"] = "Please enter your email Address.";
        }
    
        if (typeof input["email"] !== "undefined") {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(input["email"])) {
            isValid = false;
            errors["email"] = "Please enter valid email address.";
          }
        }
    
        if (!input["password"]) {
          isValid = false;
          errors["password"] = "Please enter your password.";
        }
    
        if (!input["confirm_password"]) {
          isValid = false;
          errors["confirm_password"] = "Please enter your confirm password.";
        }
    
        if (typeof input["password"] !== "undefined") {
          if(input["password"].length < 6){
            isValid = false;
            errors["password"] = "Please add at least 6 characters.";
          }
        }
    
        if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
          if (input["password"] !== input["confirm_password"]) {
            isValid = false;
            errors["password"] = "Passwords don't match.";
          }
        }
    
        setErrorMessage(errors);
        return isValid;
      };
    useEffect(() => {
        axios.get('https://domaintobesocial.com/domaintobe/gettermcondition')
        .then(res => {
          settermcondition(res.data[0].term)
        })
        .catch(error => {
            console.error('Error fetching plan data:', error);
        });
    },[])

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(input)
        if (validate()) {
            const formData = new FormData();
            formData.append('firstname', input.firstname);
            formData.append('lastname', input.lastname);
            formData.append('username', input.username);
            formData.append('email', input.email);
            formData.append('mobile', input.mobile);
            formData.append('age', input.age);
            formData.append('password', input.password);
            formData.append('referral', input.referral);

            axios.post('https://domaintobesocial.com/domaintobe/normalsignup', formData)
                .then((res) => {
                    if (res.data.message == 'success') {
                        setSuccessMessage('Account created successfully.');
                        setTimeout(() => { window.location = "/"; }, 3000);
                    } else {
                        console.log(res.data.message)
                        seterrorMessageapi(res.data.message);
                        // setTimeout(() => { setErrorMessage(''); }, 2000);
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    };
    
    return (
        <div className='in_center in_center_discussion'>
            <section className="loginpage">
                <div className="container">
                    <Link to="/Signup" className="gologin"><img src="/images/loginarrow.png" alt="icon" /></Link>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="imglogin">
                                <img src="/images/loginimg.png" alt="images" />
                            </div>
                        </div>
                        <div className="col-md-6">
                      
                            <div className="right_login right_signup" style={{ backgroundImage: `url(/images/signupbg1.jpg)` }}>
                                <h3>Sign up free account</h3>
                                <div className="alert alert-danger" id="errorlogin"></div>
                                <div className="alert alert-success" id="successlogin">Successfully Registered</div>
                                {errorMessageapi?<div className="text-danger">{errorMessageapi}</div>:''}
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>First Name*</label>
                                                <input type="text" name="firstname" value={input.firstname} onChange={handleChange} className="form-control" autoComplete="off" />
                                                <div className="text-danger">{ errorMessage.firstname}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Last Name*</label>
                                                <input type="text" name="lastname" value={input.lastname} onChange={handleChange} id="lastname" className="form-control" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.lastname}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Username*</label>
                                                <input type="text" name="username" value={input.username} onChange={handleChange} id="username" className="form-control" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.username}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Email Address*</label>
                                                <input type="text" name="email" value={input.email} onChange={handleChange} className="form-control" id="email" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.email}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Mobile Number*</label>
                                                <input type="text" name="mobile" value={input.mobile} onChange={handleChange} className="form-control" id="mobile" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.mobile}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Dob<sup>(dd/MM/yyyy)*</sup></label>
                                                <input type="date" className="form-control" name="age" value={input.age} onChange={handleChange} />
                                                {/* <DatePicker  
                                            selected={  selectedDate }  
                                            onChange={ this.handleDateChange.bind(this) }  
                                            name="startDate"  
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control" id="age" 
                                            /> */}
                                                {/* <input type="text" name="age" value={ input.age} onChange={this.handleChange}  className="form-control" id="age" autocomplete="off"/> */}
                                                <div className="text-danger">{ errorMessage.age}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Create Password*</label>
                                                <input type="password" name="password" value={input.password} onChange={handleChange} className="form-control" id="password" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.password}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Confirm Password*</label>
                                                <input type="password" name="confirm_password" value={input.confirm_password} onChange={handleChange} className="form-control" id="confirm_password" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.confirm_password}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Referral Code</label>
                                                <input type="text" name="referral" value={input.referral?input.referral:'@Dom7POgMAwV1I9ompJn'} onChange={handleChange} className="form-control" id="referral" autocomplete="off" />
                                                <div className="text-danger">{ errorMessage.referral}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                    <div className="form-group">
                                       <label>Terms and Conditions*</label>
                                        <div className="row">

                                       
                                           
                                            <div className="col-md-12 mb-2">
                                                <div className="checkcontainer">
                                                    <input type="checkbox" name="tc"   onChange={handleChange} />
                                                    <span className="radiobtn"></span>
                                            {termcondition&&termcondition}
                                                </div>
                                            </div>
                                         
                                       
                                        </div>
                                    </div>  
                                    </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn" type="submit">Create Account</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Normalsignup
