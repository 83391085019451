
import { Route, Routes } from 'react-router-dom';
import Login from './Compononets/Login/Login';
import './css/style.css'
import Userdashboard from './Compononets/Userdashboard/Userdashboard';
import Sidebar from './Compononets/Userheader/Sidebar';
import Viewnotifications from './Compononets/Viewnotifications/Viewnotifications';
import Messages from './Compononets/Messages/Messages';
import Favorites from './Compononets/Favorites/Favorites';
import Blocklist from './Compononets/Blocklist/Blocklist';
import Followers from './Compononets/Followers/Followers';
import Discussion from './Compononets/Discussion/Discussion';
import Requests from './Compononets/Requests/Requests';
import Signup from './Compononets/Signup/Signup';
import Vipsignup from './Compononets/Vipsignup/Vipsignup';
import Forget from './Compononets/Forget/Forget';
import Professions from './Compononets/Professions/Professions';
import Normalsignup from './Compononets/Normalsignup/Normalsignup';
import Userprofile from './Compononets/Userprofile/Userprofile';
import Vipuser from './Compononets/Vipuser/Vipuser';
import Help from './Compononets/Help/Help';
import Viewprofile from './Compononets/Viewprofile/Viewprofile';
import Notification from './Compononets/Notification/Notification';
import Blog from './Compononets/Blog/Blog';
import Viewblog from './Compononets/Viewblog/Viewblog';
import Createpost from './Compononets/Createpost/Createpost';
import Editpost from './Compononets/Editpost/Editpost';
import Singlediscussion from './Compononets/Discussion/Singlediscussion';
import Viewhelp from './Compononets/Viewhelp/Viewhelp';
import EditDiscussion from './EditDiscussion/EditDiscussion';
import EditHelp from './EditHelp/EditHelp';
import Termandconditions from './Termandconditions';
import Privacy from './Privacy';
import Support from './Support';
import Qrcode from './Compononets/Qrcodewebsite/Qrcode';
import { useEffect } from 'react';

function App() {


  return (
  <>

  <Routes>
    <Route path='/' element={<Login/>} />
    <Route path='/terms' element={<Termandconditions/>} />
    <Route path='/privacy' element={<Privacy/>} />
    <Route path='/support' element={<Support/>} />
    <Route path='/websiteqr' element={<Qrcode/>} />

    <Route path='/Signup' element={<><Signup/></> } />
    <Route path='/Vipsignup' element={<><Vipsignup/></> } />
    <Route path='/vipuser' element={<><Vipuser/></> } />

    <Route path='/Forget' element={<><Forget/></> } />
          <Route path='/Normalsignup' element={<><Normalsignup/></> } />
          <Route path='/userprofile' element={<Userprofile />} />
          <Route path='/viewprofile/:name' element={<Viewprofile />} />
          {/* <Route path='/viewprofile/:id' element={<Viewprofile />} /> */}
          <Route path='/Viewblog/:id' element={<Viewblog />} />
   
          <Route path='/userdashboard' element={<div className="maindiv"><Sidebar/><Userdashboard/></div> } />
          <Route path='/messages' element={<div className="maindiv"><Sidebar/><Messages/></div> } />
          <Route path='/viewnotifications'  element={<div className="maindiv"><Sidebar/><Viewnotifications/></div> } />
          <Route path='/Notification/:id'  element={<div className="maindiv"><Sidebar/><Notification/></div> } />
          <Route path='/favorites' element={<div className="maindiv"><Sidebar /><Favorites /></div>} />
          <Route path='/blocklist' element={<div className="maindiv"><Sidebar /><Blocklist /></div>} />
          <Route path='/followers' element={<div className="maindiv"><Sidebar /><Followers /></div>} />
          <Route path='/requests' element={<div className="maindiv"><Sidebar /><Requests /></div>} />
          <Route path='/help' element={<div className="maindiv"><Sidebar /><Help /></div>} />
          <Route path='/discussion' element={<div className="maindiv"><Sidebar /><Discussion /></div>} />
          <Route path='/singlediscussion' element={<div className="maindiv"><Sidebar /><Singlediscussion /></div>} />
          <Route path='/professions/:id' element={<div className="maindiv"><Sidebar/><Professions/></div> } />
          <Route path='/Blog' element={<div className="maindiv"><Sidebar/><Blog/></div> } />
          <Route path='/Createpost' element={<div className="maindiv"><Sidebar/><Createpost/></div> } />
          <Route path='/editPost/:id' element={<div className="maindiv"><Sidebar/><Editpost/></div> } />
          <Route path='/editDiscussion/:id' element={<div className="maindiv"><Sidebar/><EditDiscussion/></div> } />
          <Route path='/editHelp/:id' element={<div className="maindiv"><Sidebar/><EditHelp/></div> } />
          <Route path='/Viewhelp/:id' element={<div className="maindiv"><Sidebar/><Viewhelp/></div> } />
          {/* <Route path='/Help' element={<><Sidebar/><Help/></> } /> */}
  </Routes>

  
  </>
  );
}

export default App;
